<template>
  <b-card title="Listar Paises">
    <listagemPais />
  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BCard,
} from 'bootstrap-vue'
import listagemPais from '../../Listagens/Paises/ListarPais.vue'

export default {
  components: {
    BCard, listagemPais,
  },

}
</script>
